export const getAppConfig = state => state.appConfig || {};
export const getSectionUrl = state => getAppConfig(state).sectionUrl;
export const getPostPageSectionUrl = state => getAppConfig(state).postPageSectionUrl;
export const getCategoryPath = state => getAppConfig(state).getCategoryPath;
export const getLogBiToConsole = state => getAppConfig(state).logBiToConsole;
export const getBundleName = state => getAppConfig(state).bundleName;
export const getIsDebugEnabled = state => getAppConfig(state).debugEnabled;
export const getImageHost = state => getAppConfig(state).imageHost;
export const getVideoHost = state => getAppConfig(state).videoHost;

const isBundleName = (state, name) => getBundleName(state) === name;
export const getIsAppBundle = state => isBundleName(state, 'blog');
export const getIsPostPageBundle = state => isBundleName(state, 'post-page');

export const getIsTemplate = state => getSectionUrl(state).indexOf('//www.wix.com/') > -1;
